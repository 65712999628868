<template>
    <a :href="href" :target="target" class="flex flex-row gap-2 p-3 rounded-lg hover:bg-app-gradient-from">
        <div class="flex flex-col justify-center">
            <Icon :icon="icon" class="text-app-yellow h-11" width="50"/>
        </div>
        <div class="flex flex-col">
            <vue3-autocounter class="text-2xl font-bold" :startAmount="0" :endAmount="count" separator=""/>
            <span :class="`${color} text-sm`">{{ text }}</span>
        </div>
    </a>
</template>

<script>

export default {
    name: 'Stat',
    props: {
        count: {
            type: Number,
            required: true,
        },
        icon: {
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: "text-white",
        },
        href: {
            type: String,
        },
        target: {
            type: String,
        },
    },
}

</script>
