import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createInertiaApp, Link } from '@inertiajs/inertia-vue3';
import { createApp, h } from 'vue';
import axios from 'axios';
import Vue3Autocounter from 'vue3-autocounter';
import { MotionPlugin } from '@vueuse/motion';
// import Wysiwyg from './Components/Wysiwyg';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

createInertiaApp({
    resolve: (name) => require(`./Pages/${name}`),
    setup({
              el,
              App,
              props,
              plugin,
          }) {
        createApp({
            name: 'SmokesServers',
            render: () => h(App, props),
        })
            .use(plugin)
            .use(MotionPlugin)
            .component('vue3-autocounter', Vue3Autocounter)
            .component('Icon', FontAwesomeIcon)
            .component('Link', Link)
            // .component('Wysiwyg', Wysiwyg)
            .mount(el);
    },
}).catch(console.error);
