<template>
    <a class="relative hover:scale-110 transition-all shadow rounded-lg" :href="href" target="_blank" :title="playtime ? `Playtime: ${Math.floor(playtime / 60 / 60)} hours` : ''">
        <img class="w-full rounded-lg select-none" :src="pfp" :alt="`${name}'s profile picture`"/>
        <div class="w-full h-full absolute top-0 rounded-lg" style="background: linear-gradient(360deg, rgba(0, 0, 0, .7) 0%, rgba(35, 35, 49, .4) 25%, rgba(24, 24, 30, 0) 40%);"/>
        <div class="font-black text-lg leading-4 p-2 bg-app-gradient-to rounded absolute top-0 mt-3 -ml-5 bg-opacity-80 border border-white border-opacity-5 brightness-125" :style="`color: ${color}`">{{ rank }}</div>
        <div class="font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap absolute bottom-0 p-2 w-full">{{ name }}</div>
    </a>
</template>

<script>
export default {
    name: 'Staff',
    props: {
        name: {
            type: String,
            required: true,
        },
        href: {
          type: String,
            required: false,
        },
        rank: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: '#fff'
        },
        pfp: {
            type: String,
            required: true,
        },
        playtime: {
            type: Number,
            required: false,
        },
    },
}
</script>
