<template>
    <header class="w-full mb-6 py-4 z-10">
        <div class="center text-white flex flex-row justify-between">
            <div class="flex flex-row">
                <img alt="" class="w-12" src="/images/logo.svg" />

                <ul class="space-x-8 my-auto ml-8 flex">
                    <li v-for="({ name, url }) in links">
                        <Link v-if="url.startsWith('/')"
                            :href="url"
                            v-bind:class="{'text-white border-b': $page.url === url, 'text-app-alt hover:text-app-text': $page.url !== url, ' py-1.5': true}">
                            {{ name }}
                        </Link>
                        <a v-else
                           :href="url"
                           class="text-app-alt hover:text-app-text py-1.5">
                            {{ name }}
                        </a>
                    </li>
                </ul>
            </div>

            <div v-if="!user" class="my-auto">
                <a href="/login" class="py-1.5 rounded-md text-app-alt hover:text-app-text">
                    Login
                </a>
            </div>

            <div v-else class="my-auto flex flex-row rounded-full shadow-lg relative" @click="toggleDropdown">
                <img :src="user.avatar" class="w-8 h-8 rounded-full cursor-pointer hover:outline outline-1 outline-offset-2 outline-white" :class="{'outline': active}" alt="User Avatar"/>
                <div v-if="active" class="absolute top-12 right-0">
                    <ul class="bg-app-bg py-2 px-2 rounded-md text-app-alt space-y-1">
                        <li v-for="({name, url}) in dropdown">
                            <Link class="bg-app-bg py-1 px-2 block hover:brightness-125 rounded cursor-pointer" :href="url">{{ name }}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { usePage } from '@inertiajs/inertia-vue3';

export default {
    name: 'Header',

    data() {
        return {
            active: false,
        }
    },
    methods: {
      toggleDropdown() {
        this.active = !this.active;
      }
    },
    setup() {
        /** @var {{avatar: string}} user */
        const user = usePage().props.value.auth.user;
        const header = Object.values(usePage().props.value.links.header)[0]; //😰

        return {
            user,
            links: [
                {
                    name: 'Home',
                    url: '/',
                },
                {
                    name: 'Rules',
                    url: '/rules',
                },
                ...header,
            ],
            dropdown: [
                {
                    name: 'Dashboard',
                    url: '/nova',
                },
                {
                    name: 'Logout',
                    url: '/logout',
                },
            ],
        }
    },
}
</script>
