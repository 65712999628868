<template>
    <div class="flex flex-col w-full pb-96">
        <Header v-if="showHeader" :class="`${absolute ? `absolute` : ``}`"/>

        <div v-if="!absolute" class="header-container center">
            <h1 v-if="title" class="header-title pr-6">
                {{ title }}
            </h1>

            <slot name="header" />
        </div>

        <div v-bind:class="{ 'pt-0': showHeader, 'flex-1 flex flex-col': true }">
            <div class="h-full w-full flex-1 text-white">
                <slot />
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from './Header';
import Footer from './Footer';

export default {
    name: 'Layout',
    components: { Header, Footer },

    created() {
      document.title = `Smokes ${this.title ? `| ${this.title}` : 'Servers'}`;
    },

    setup() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let isGame = params.game !== null;

        return {
            showHeader: !isGame,
        }
    },

    props: {
        title: String,
        absolute: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
