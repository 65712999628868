<template>
    <Layout absolute>
        <!--HERO-->
        <div class="h-screen w-full relative overflow-hidden">
            <Transition
                enter-active-class="duration-[9000ms] ease-out"
                enter-from-class="transform opacity-0 scale-125"
                enter-to-class="opacity-100"
                leave-active-class="duration-[500ms] ease-in"
                leave-from-class="opacity-100 scale-100"
                leave-to-class="transform opacity-0"
            >
                <div class="absolute h-screen w-full bg-cover bg-no-repeat bg-center" v-bind:style="`background-image: url('images/hero/${images[currentImage]}')`" :key="currentImage"/>
            </Transition>
            <div class="absolute bottom-0 w-full h-full bg-hero"/>
            <div class="center flex flex-col h-full">
                <div class="flex relative justify-center h-full text-center sm:text-left pt-24">
                    <div class="flex flex-col gap-6 sm:basis-1/2 justify-center" v-motion :initial="{x: -100}" :enter="{x: 0}" :delay="100">
                        <h1 class="font-staatliches text-7xl sm:text-8xl" style="text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);">Smokes<br/>Servers</h1>
                        <p class="text-white leading-5" style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);">Hosting High Quality Servers For Our Community Since 2019!</p>
                        <div class="inline-flex justify-center sm:justify-start">
                            <Button text="Discord" bg-color="bg-app-yellow" text-color="text-black" :icon="faDiscord" class="mr-4 drop-shadow-xl" href="https://discord.gg/smokes"/>
                            <Button text="Steam Group" bg-color="bg-[#171C22]" text-color="text-white" :icon="faSteam" class="drop-shadow-xl" href="https://steamcommunity.com/groups/SmokesServers"/>
                        </div>
                    </div>
                    <div class="sm:basis-1/2 basis-0 justify-center flex" v-motion :initial="{x: 100}" :enter="{x: 0}" :delay="100">
                        <img src="/images/logo.svg" alt="Smoke Logo" class="w-[30rem] ml-auto hidden sm:block" style="filter: drop-shadow(10px 10px 2px rgba(0, 0, 0, 0.25));"/>
                    </div>
                </div>
                <div class="relative flex sm:flex-row flex-col justify-evenly py-12 gap-2">
                    <Stat color="text-[#5865F2]" text="Discord Members" :icon="faDiscord" :count="metrics.discord_members" href="https://discord.gg/smokes" target="_blank"/>
                    <Stat color="text-[#62A569]" text="In-game Players" :icon="faGamepadModern" :count="metrics.players" href="#servers"/>
                    <Stat text="Group Members" :icon="faSteam" :count="metrics.steam_group" href="https://steamcommunity.com/groups/SmokesServers" target="_blank"/>
                </div>
            </div>
        </div>

        <!--SERVERS-->
        <div class="center mt-20" id="servers">
            <h2 class="subheader-title mb-6" v-motion :initial="{y: 100}" :visibleOnce="{y: 0}">Servers</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                <Server v-for="(server, index) in servers" :server="server" class="md:first:col-span-2 lg:first:col-span-1" v-motion :initial="{y: 100}" :visibleOnce="{y: 0}" :delay="index * 50"/>
            </div>
        </div>

        <!--SERVER INFO-->
        <div class="overflow-hidden">
            <div class="center relative">
                <img src="/images/grid.svg" width="250" class="absolute -right-12 select-none"/>
            </div>
            <div class="flex flex-col gap-24 sm:mt-64 sm:mb-52 my-20" id="info">
                <div v-for="(info, index) in infos" class="flex sm:flex-row flex-col sm:even:flex-row-reverse center gap-14" v-motion :initial="{x: index % 2 === 0 ? -50 : 50}" :visibleOnce="{x: 0, transition: {duration: 400, ease: 'backOut'}}" :delay="100">
                    <div class="basis-1/2 relative">
                        <div :class="`${index % 2 === 0 ? 'right-0 -translate-x-[200px]' : 'left-0 translate-x-[200px]'} sm:block hidden absolute w-[10000px] h-[calc(100%+80px)] -translate-y-[25px] -z-10 rounded-lg bg-gradient-to-r from-app-gradient-from to-app-gradient-toLesser`"/>
                        <img :src="info.image_url" alt="" :class="`rounded-xl sm:w-[95%] aspect-video ${index %2 === 0 ? 'sm:mr-auto' : 'sm:ml-auto'}`"/>
                    </div>
                    <div class="flex flex-col justify-center basis-1/2 gap-4">
                        <h2 class="subheader-title">{{ info.title }}</h2>
                        <p v-html="info.description" class="text-app-alt font-light leading-5"/>
                    </div>
                </div>
            </div>
            <div class="center relative">
                <img src="/images/triangle.svg" width="175" class="absolute left-56 bottom-10 select-none -z-10"/>
            </div>
        </div>

        <!--SUPPORTER-->
        <div class="w-full overflow-hidden" v-motion :initial="{y: 100}" :visibleOnce="{y: 0}" :delay="200" id="supporter">
            <div class="w-full h-72 bg-gradient-to-r from-app-gradient-from to-app-gradient-to mt-20">
                <div class="h-full center flex place-content-between relative">
                    <div class="h-full w-12 bg-app-bg absolute -left-16"/>
                    <div class="h-full w-12 bg-app-bg absolute -left-44"/>
                    <div class="flex flex-col max-w-xl gap-4 justify-center text-center sm:text-left">
                        <h2 class="text-[10vw] sm:text-6xl font-staatliches">Become a supporter</h2>
                        <p class="text-app-alt font-light leading-5">Our servers offer an array of appealing upgrades, benefits, and much more to purchase!</p>
                        <Button text="Store" bg-color="bg-app-yellow" :icon="faGem" class="sm:self-start self-center" href="https://smokesservers.com/shop/"/>
                    </div>
                    <img src="/images/diamond.svg" alt="" class="w-24 sm:w-48 absolute sm:relative top-0 left-1/2 sm:left-0 -translate-x-1/2 -translate-y-1/2 sm:translate-x-0 sm:translate-y-0"/>
                    <div class="h-full w-12 bg-app-bg absolute -right-16"/>
                    <div class="h-full w-12 bg-app-bg absolute -right-44"/>
                </div>
            </div>
        </div>

        <!--STAFF ROSTER-->
        <div class="overflow-hidden" id="staff">
            <div class="center sm:mt-52 mt-20 pb-4 relative">
                <h2 class="subheader-title mb-6" v-motion :initial="{y: 100}" :visibleOnce="{y: 0}">Staff Roster</h2>
                <span class="absolute -right-[20%] -top-28 font-staatliches text-10xl text-app-gradient-from -z-10 select-none">STAFF</span>
                <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-10 lg:nth-child-5n:" v-motion :initial="{y: 100}" :visibleOnce="{y: 0}">
                    <template v-for="(group, rank) in people" :key="rank">
                        <Staff v-for="person in group" :name="person.name" :color="ranks[rank].web_color" :rank="rank" :pfp="person.avatar" :href="`https://steamcommunity.com/profiles/${person.real_id}`" :playtime="person.play_time"/>
                    </template>
                </div>
                <span class="absolute -left-[20%] -bottom-[5%] font-staatliches text-10xl text-app-gradient-from -z-10 select-none">STAFF</span>
            </div>
        </div>

    </Layout>
</template>

<script>
import Layout from "../Components/Layout";
import Button from "../Components/Button";
import Server from "../Components/Server";
import Staff from "../Components/Staff";
import Stat from "../Components/Stat";
import { faDiscord, faSteam } from '@fortawesome/free-brands-svg-icons';
import { faGem } from '@fortawesome/pro-regular-svg-icons';
import { faGamepad, faGamepadModern } from '@fortawesome/pro-solid-svg-icons';
import { computed } from "vue";
import { usePage } from "@inertiajs/inertia-vue3";

export default {
    name: 'Home',
    components: { Number, Layout, Button, Server, Staff, Stat },

    setup() {
        const servers = computed(() => usePage().props.value.servers ?? []);
        const metrics = computed(() => usePage().props.value.metrics ?? []);
        const infos = computed(() => usePage().props.value.info ?? []);
        const people = computed(() => usePage().props.value.people ?? []);
        const ranks = computed(() => usePage().props.value.ranks ?? []);

        return { faDiscord, faSteam, faGem, faGamepad, faGamepadModern, servers, metrics, infos, people, ranks }
    },
    data() {
        return {
            currentImage: 0,
            images: [
                "hero.jpg",
                "hero2.jpg",
            ],
        }
    },
    mounted() {
        setInterval(() => {
            this.currentImage = (this.currentImage + 1) % this.images.length;
        }, 10 * 1000);
    }
}
</script>
