<template>
    {{ DateTime.fromISO(iso).toLocaleString(format ?? DateTime.DATETIME_MED) }}
</template>

<script>
import { DateTime } from 'luxon';

export default {
    name: 'TimeStamp',
    props: {
        iso: {
            default: '',
            type: String,
        },

        format: {
            default: () => ({}),
            type: Object,
        },
    },

    setup() {
        return {
            DateTime,
        };
    },
}
</script>
