<template>
    <footer class="w-full bg-gradient-to-r from-app-gradient-from to-app-gradient-to absolute bottom-0 pt-16 pb-32 flex justify-center text-app-alt font-light">
        <div class="flex center basis-full justify-between gap-20">
            <img alt="Smoke Logo" width="100" src="/images/logo.svg" />
            <div class="flex flex-wrap gap-14 text-right justify-end">
                <ul v-for="(catLinks, category) in links" :id="category">
                    <li class="text-app-yellow font-bold">{{ category }}</li>
                    <li v-for="link in catLinks" :id="link.name">
                        <a :href="link.url" target="_blank" class="hover:text-white">{{ link.name }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="absolute bottom-0 center w-full">
            <div class="border-t border-white border-opacity-10 w-full"></div>
            <div class="flex sm:flex-row flex-col text-center justify-between my-4">
                <span>Smokes Servers © {{ new Date().getFullYear() }}</span>
                <span>Made with ❤ by <b class="text-white font-bold">REAZN</b> & <b class="text-white font-bold">Stubbo</b></span>
            </div>
        </div>
    </footer>
</template>

<script>
import { computed } from 'vue';
import { usePage } from '@inertiajs/inertia-vue3';

export default {
    name: 'Footer',
    setup() {
        const links = computed(() => usePage().props.value.links?.footer ?? {});

        return {
            links
        }
    }
}

</script>
