<template>
    <a :href="href" target="_blank" :class="`${disabled ? `bg-neutral-800 text-neutral-400 cursor-not-allowed` : `hover:brightness-110 ${bgColor} ${textColor} cursor-pointer`} py-1 px-3 font-staatliches text-lg rounded-md text-center`">
        <Icon :icon="icon" :class="`mr-2 ${iconColor}`"/>
        {{ text }}
    </a>
</template>

<script>
export default {
    name: 'Input',

    props: {
        text: {
            type: String,
            required: true,
        },
        textColor: {
            type: String,
            default: "text-black",
        },
        icon: {
            required: false,
        },
        iconColor: {
            type: String,
            required: false,
        },
        bgColor: {
            type: String,
            default: "bg-app-yellow",
        },
        href: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

}
</script>
