<template>
    <div class="relative my-auto rounded-md shadow-sm w-full">
        <slot name="before" />

        <input
            :type="type"
            :name="id"
            :id="id"
            :class="[
                'block w-full py-2 pl-10 sm:text-sm bg-app-header rounded-md',
                'placeholder-white/50 shadow-md outline-none text-white',
            ]"
            :placeholder="placeholder"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        />

        <slot name="after" />
    </div>
</template>

<script>
export default {
    name: 'Input',

    props: {
        modelValue: [String, null],

        type: {
            type: String,
            default: 'text',
        },

        id: {
            type: String,
        },

        placeholder: {
            type: String,
        },
    },

    emits: ['update:modelValue'],
}
</script>

<style scoped>

</style>
