<template>
    <TransitionRoot
        :show="show"
        enter="transition-opacity duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-1000"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
        <div class="relative transform -translate-x-2/4">
            <div class="bg-black text-white text-md rounded py-1 px-4 right-0 bottom-full font-bold">
                {{ text }}
                <svg
                    class="absolute text-black h-2 w-full left-0 top-full"
                    x="0px"
                    y="0px"
                    viewBox="0 0 255 255"
                    xml:space="preserve"
                >
          <polygon
              class="fill-black "
              points="0,0 127.5,127.5 255,0"
          />
        </svg>
            </div>
        </div>
    </TransitionRoot>
</template>

<script>
import { TransitionRoot } from '@headlessui/vue';

export default {
    name: 'ToolTip',
    components: {
        TransitionRoot,
    },
    props: {
        show: Boolean,
        text: {
            default: '',
            type: String,
        },
    },
}
</script>
