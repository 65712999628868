<template>
    <div class="flex flex-col w-full bg-gradient-to-r from-app-gradient-from to-app-gradient-toLesser rounded-xl p-6 gap-4 shadow border border-white border-opacity-0 hover:border-opacity-5">
        <div class="flex place-content-between">
            <div class="flex flex-col max-w-[95%]">
                <span class="font-medium overflow-hidden overflow-ellipsis whitespace-nowrap">{{ server.name?.replaceAll("|", " | ") ?? "Smokes.gg" }}</span>
                <span class="text-app-alt">{{`${server.online ? `${server.players}/${server.max_players}` : "Offline"}`}}</span>
            </div>
            <div :class="`w-3 h-3 rounded-full ${server.online ? `bg-green-400` : `bg-red-400`}`"/>
        </div>
        <Button text="connect" bg-color="bg-black" text-color="text-app-yellow" :icon="faSteam" :disabled="!server.online" :href="`steam://connect/${server.ip}:${server.port}`"/>
    </div>
</template>

<script>
import Button from '../Components/Button';
import { faSteam } from '@fortawesome/free-brands-svg-icons';

export default {
    name: 'Input',
    components: { Button },

    props: {
        server: {
            type: Object,
            required: true
        }
    },

    setup() {
        return { faSteam }
    }
}
</script>
